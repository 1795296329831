html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
}
html {
  width: 100%;
  font-size: 100%;
  line-height: 1.6;
}
body {
  position: relative;
  background-color: $color_white;
  @include fontfam-ja;
  font-style: normal;
  font-size: 15px;
  letter-spacing: 0.015em;
  -webkit-text-size-adjust: 100%;
	word-wrap:break-word;
	-webkit-overflow-scrolling: touch;
  vertical-align: baseline;
  color: $color_text;
  @include breakSP {
    font-size: 13px;
  }
  &.page-home {
    padding-top: 0;
  }
}
* {
  font-feature-settings: "palt";
  box-sizing: border-box;
  word-wrap: break-word;
  word-break: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1;
}
ol, ul, li {
	list-style: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
caption, th, td {
	text-align: left;
	font-weight: normal;
	vertical-align: middle;
}
q, blockquote {
  	quotes: none;
}
q:before, q:after, blockquote:before, blockquote:after {
	content: "";
	content: none;
}

a img {
	border: none;
}
img,
svg {
	vertical-align: bottom;
	max-width: 100%;
	height: auto;
}


.wrap {
  position: relative;
  width: 100%;
  max-width: $width_base;
  margin: 0 auto;
	@include baseOverWrap_over_pa {
    padding: 0 15px;
  }
}
.inner_wrap {
  width: 100%;
	max-width: $width_base;
	margin: 0 auto;
}


a {
  color: $color_main;
	text-decoration: none;
}
a:active,
a:hover {
	outline-width: 0;
	transition: all 0.3s 0s ease;
		-webkit-transition: all 0.3s 0s ease;
		-moz-transition: all 0.3s 0s ease;
		-o-transition: all 0.3s 0s ease;
}
a:link:hover, a[href]:hover,#bt input:hover {
	zoom: 1;
}
a[href^="tel:"] {
	@include breakSP {
		display: inline-block;
		pointer-events: none;
		text-decoration: none;
	}
}
.-anchor {
  margin-top: -80px;
  padding-top: 80px;
	@include breakSP {
    margin-top: -60px;
    padding-top: 60px;
	}
}

input[type="text"],
input[type="number"],
input[type="tel"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  display: block;
  width: 100%;
  height: 50px;
  padding: .5em;
  background-color: $color_gray;
  border: solid 1px $color_light_blue;
  border-radius: 3px;
  line-height: 1;
  cursor: pointer;
  @include breakSP {
    height: 50px;
    border-radius: 3px;
    font-size: 13px;
  }
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  height: 48px;
  padding: .5em;
  background-color: $color_gray;
  border: none;
  border-radius: 3px;
  line-height: 1;
  cursor: pointer;
  @include breakSP {
    font-size: 13px;
  }
}
input[type="submit"],
input[type="reset"] {
	cursor: pointer;
}
::placeholder {
  color: rgba($color: $color_text, $alpha: .8);
}
textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  padding: 10px 20px 10px 20px;
  background-color: $color_gray;
  border: solid 1px $color_light_blue;
  border-radius: 0;
  cursor: pointer;
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.pt60 {
  padding-top: 60px;
}
.pb60 {
  padding-bottom: 60px;
}

.img100 {
	width: 100%;
	height: auto;
}

.onlyPC {
	display: block !important;
	@include breakSP {
		display: none !important;
	}
}
.underTAB {
  display: none !important;
	@include breakTAB {
    display: block !important;
	}
}
.onlySP {
	display: none !important;
	@include breakSP {
		display: block !important;
	}
}
.en {
  @include fontfam-en;
}


// displayAnimation
///////////////////////////////////////
@keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}
@-moz-keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}

	100% {
		display: block;
		opacity: 1;
	}
}
@-webkit-keyframes fade-in {
	0% {
		display: none;
		opacity: 0;
	}

	1% {
		display: block;
		opacity: 0;
	}
	100% {
		display: block;
		opacity: 1;
	}
}


.fadeIn {
  opacity: 0;
  transition: 2s;
  &.is-show {
    opacity: 1;
  }
}
.fadeIn_left {
  opacity: 0;
  transform: translate(-50%, 0);
  transition: 1.5s;
  &.is-show {
    transform: translate(0, 0);
    opacity: 1;
  }
}
.fadeIn_right {
  opacity: 0;
  transform: translate(50%, 0);
  transition: 1.5s;
  &.is-show {
    transform: translate(0, 0);
    opacity: 1;
  }
}




// page_fv
///////////////////////////////////////
.page_fv {
  position: relative;
  width: 100%;
  min-height: 260px;
  margin-bottom: 60px;
  background: url(../img/blog/fv.png) no-repeat center bottom;
  background-size: cover;
  @include breakSP {
    min-height: 160px;
    margin-bottom: 30px;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: $color_white, $alpha: .7);
  }
  .wrap {
    padding: 125px 0 50px;
    text-align: center;
    color: $color_main;
    @include breakSP {
      padding: 70px 0 30px;
    }
  }
  &__title {
    margin-bottom: 26px;
    @include fontfam-en;
    font-size: 50px;
    line-height: 1;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    @include breakSP {
      margin-bottom: 10px;
      font-size: 30px;
    }
  }
  &__catchcopy {
    font-size: 13px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.05em;
    @include breakSP {
      font-size: 11px;
    }
  }
  .solution & {
    background: url(../img/solution/fv.png) no-repeat center bottom;
    background-size: cover;
  }
  .recruit &,
  .entry & {
    background: url(../img/recruit/fv.png) no-repeat center bottom;
    background-size: cover;
  }
  .company & {
    background: url(../img/company/fv.png) no-repeat center bottom;
    background-size: cover;
  }
  .privacy &,
  .contact & {
    background: url(../img/contact/fv.png) no-repeat center bottom;
    background-size: cover;
  }
  .blog & {
    background: url(../img/blog/fv.png) no-repeat center bottom;
    background-size: cover;
  }
}


.parts-table {
  margin-bottom: 40px;
  border-top: solid 1px $color_light_blue;
  &_list {
    display: flex;
    border-bottom: solid 1px $color_light_blue;
    @include breakSP {
      display: block;
    }
    .title {
      width: 200px;
      padding: 15px 30px;
      background-color: $color_gray;
      font-size: 15px;
      font-weight: bold;
      @include breakSP {
        width: 100%;
        padding: 10px 15px;
        font-size: 13px;
      }
    }
    .detail {
      width: calc( 100% - 200px );
      padding: 15px 30px;
      font-size: 15px;
      line-height: 2;
      @include link_opacity;
      @include breakSP {
        width: 100%;
        padding: 15px;
        font-size: 13px;
      }
      :not(.recruit-flow) {
        li {
          position: relative;
          padding-left: 1em;
          &::before {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            display: inline-block;
            width: 6px;
            height: 6px;
            background-color: $color_main;
            border-radius: 30px;
            @include breakSP {
              top: 10px;
            }
          }
        }
      }
    }
    a {
      text-decoration: underline;
      color: $color_text;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
.parts-faq {
  margin-bottom: 40px;
  border-top: solid 1px $color_light_blue;
  &_block {
    display: block;
    border-bottom: solid 1px $color_light_blue;
    .question {
      position: relative;
      width: 100%;
      padding: 15px 30px 15px 70px;
      background-color: $color_gray;
      font-size: 15px;
      font-weight: bold;
      @include breakSP {
        padding: 15px 15px 15px 40px;
        font-size: 13px;
      }
      &::before {
        content: 'Q.';
        position: absolute;
        top: 0.5em;
        left: 30px;
        width: 40px;
        margin-right: 10px;
        @include fontfam-en;
        font-size: 25px;
        font-weight: 400;
        line-height: 1;
        color: $color_main;
        @include breakSP {
          top: 0.65em;
          left: 15px;
          width: 30px;
          font-size: 20px;
        }
      }
    }
    .answer {
      position: relative;
      width: 100%;
      padding: 15px 30px 15px 70px;
      font-size: 15px;
      line-height: 2;
      @include link_opacity;
      @include breakSP {
        padding: 15px 15px 15px 40px;
        font-size: 13px;
      }
      &::before {
        content: 'A.';
        position: absolute;
        top: 0.6em;
        left: 30px;
        width: 40px;
        margin-right: 10px;
        @include fontfam-en;
        font-size: 25px;
        font-weight: 400;
        line-height: 1;
        color: $color_main;
        @include breakSP {
          top: 0.8em;
          left: 15px;
          width: 30px;
          font-size: 20px;
        }
      }
    }
    a {
      text-decoration: underline;
      color: $color_text;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}



.main_contents {
  ._section {
    margin-bottom: 80px;
    @include breakSP {
      margin-bottom: 40px;
    }
  }
  ._inner {
    padding: 0 50px;
    @include breakSP {
      padding: 0 25px;
    }
  }
  .section_title {
    @include parts-section_title;
  }
  .section-detail {
    margin-bottom: 60px;
    @include breakSP {
      margin-bottom: 30px;
    }
    &:last-child {
      margin-bottom: 0;
      @include breakSP {
        margin-bottom: 0;
      }
    }
    &_title {
      @include parts-section_detail_title;
    }
    &_sub_title {
      position: relative;
      margin-top: 40px;
      margin-bottom: 10px;
      padding-left: 18px;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.1em;
      color: $color_text;
      @include breakSP {
        font-size: 16px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 10px;
        height: 1px;
        transform: translateY(-50%);
        background-color: $color_light_blue;
      }
      &:first-of-type {
        margin-top: 0;
      }
    }
    &_text {
      font-size: 15px;
      line-height: 2;
      letter-spacing: 0.05em;
      color: $color_text;
      @include breakSP {
        font-size: 13px;
      }
    }
  }
}

.button_block {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 60px;
  .btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 360px;
    height: 80px;
    background-color: $color_white;
    border: solid 5px $color_main;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.05em;
    color: $color_main;
    @include breakSP {
      max-width: 260px;
      height: 60px;
      border: solid 3px $color_main;
      font-size: 16px;
    }
    &:hover,
    &:focus {
      background-color: $color_main;
      color: $color_white;
    }
    &::before {
      content: '';
      position: absolute;
      top: 56%;
      right: 15px;
      transform: translateY(-50%);
      width: 41px;
      height: 1px;
      background-color: $color_main;
      @include anime03;
      @include breakSP {
        right: 10px;
        width: 21px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: calc( 56% + -5px );
      right: 12px;
      transform: translateY(-50%) rotate(45deg);
      width: 15px;
      height: 1px;
      background-color: $color_main;
      @include anime03;
      @include breakSP {
        top: calc( 56% + -3px );
        right: 8px;
        width: 9px;
      }
    }
    &:hover,
    &:focus {
      background-color: $color_main;
      color: $color_white;
      &::before {
        right: 9px;
        background-color: $color_white;
      }
      &::after {
        right: 6px;
        background-color: $color_white;
      }
    }
  }
}
