// header -- common
///////////////////////////////////////
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(#a7cde2 , .7);
  box-sizing: border-box;
  @include anime05;
  z-index: 9000;
  @include breakSP {
    display: block;
    padding: 0;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    padding: 0;
    @include anime05;
    @include baseOverWrap_over_pa {
      padding: 0 15px;
    }
    @include breakSP {
      height: 45px;
    }
  }
  &.active {
    background-color: rgba(#a7cde2 , .7);
    .wrap {
      height: 60px;
      @include breakSP {
        height: 45px;
      }
    }
    .header_logo {
      width: 120px;
      @include breakSP {
        width: 84px;
      }
    }
  }
  &_logo {
    width: 168px;
    @include link_opacity;
    padding: 0;
    .header.active & {
      padding: 0;
    }
    @include breakSP {
      width: 84px;
    }
    .fixbody & {
      z-index: 99999;
    }
  }
  &_nav {
    display: block;
    @include breakPC {
      display: block;
    }
    @include breakSP {
      display: none;
      overflow: scroll;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      padding: 60px 60px 20px;
      background-color: rgba($color_main , .95);
    }
    &--wrap {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include breakSP {
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;
      }
    }
    &--list_pc {
      display: flex;
      @include breakSP {
        display: none;
      }
    }
    &--list_sp {
      display: none;
      @include breakSP {
        display: block;
        width: 100%;
      }
    }
    &--list {
      .list_item {
        margin-right: 26px;
        @include breakSP {
          margin-right: 0;
          padding: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &:first-child {
          @include breakSP {
            border-top: solid 1px rgba($color_white , .3);
          }
        }
      }
    }
    &--pearent {
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0;
      @include breakSP {
        position: relative;
        display: block;
        padding: 20px 10px;
        border-bottom: solid 1px rgba($color_white , .3);
      }
      .header.active & {
        padding: 35px 0;
        @include breakSP {
          line-height: 1;
          padding: 15px 10px;
        }
      }
      .page-mypage .header.active & {
        padding: 0 0 0 30px;
      }
      .name {
        position: relative;
        display: block;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0.075em;
        color: $color_main;
        @include anime03;
        @include baseOverWrap_over_pa {
          font-size: 1.2vw;
        }
        @include breakSP {
          font-size: 15px;
          color: $color_white;
        }
        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: -3px;
          width: 0;
          height: 2px;
          background-color: $color_main;
          @include anime03;
          @include breakSP {
            height: 0;
          }
        }
        &:hover {
          &::before {
            width: 100%;
          }
        }
        /*
        .page-solution &._solution {
          border-bottom: solid 1px $color_main;
        }
        .page-about &._about {
          border-bottom: solid 1px $color_main;
        }
        .page-access &._access {
          border-bottom: solid 1px $color_main;
        }
        .page-recruitment &._recruitment {
          border-bottom: solid 1px $color_main;
        }
        */
      }
    }
    &--link {
      display: none;
      @include breakSP {
        display: block;
        width: 100%;
        margin-top: 23px;
      }
      .phone {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 10px;
        a {
          line-height: 1;
          color: $color_white;
        }
        .en {
          font-style: italic;
          font-size: 20px;
          font-weight: 300;
          letter-spacing: 0.15em;
        }
        .number {
          @include fontfam-en;
          font-style: italic;
          font-size: 25px;
          font-weight: 300;
          letter-spacing: 0.05em;
        }
      }
      .contact_link {
        margin-bottom: 20px;
        text-align: center;
        a {
          position: relative;
          display: inline-block;
          padding-left: 20px;
          font-size: 13px;
          color: $color_white;
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 11px;
            background: url(../img/icon_mail.svg) no-repeat center center;
            background-size: 100%;
          }
        }
      }
      .sns_link {
        display: flex;
        justify-content: center;
        align-items: center;
        .list_item {
          width: 20px;
          margin-right: 20px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .menu-icon {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
    width: 45px;
    height: 45px;
    box-sizing: border-box;
    z-index: 999999;
    @include breakSP {
      display: block;
      cursor: pointer;
    }
    &_wrap {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      cursor: pointer;
    }
    span {
      display: block;
      width: 20px;
      height: 1px;
      margin-bottom: 8px;
      background-color: $color_text;
      cursor: pointer;
      @include anime03;
      &.first {
        width: 15px;
        margin: 0 0 8px auto;
      }
      &.third {
        width: 15px;
        margin: 0 auto 0 0;
      }
    }
    &.active {
      .first {
        width: 30px;
        margin-top: 20px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        background-color: $color_white;
      }
      .second {
        position: relative;
        top: -9px;
        width: 30px;
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        background-color: $color_white;
      }
      .third {
        display: none;
      }
    }
  }
}
.fixbody {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .header_logo {
    display: none;
  }
}



// footer -- common
///////////////////////////////////////
.footer {
  width: 100%;
  text-align: center;
  &-contact {
    padding: 50px 15px;
    background-color: $color_white;
    text-align: center;
    &_btn {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 500px;
      height: 80px;
      margin: 0 auto 20px;
      background-color: $color_main;
      border: solid 1px $color_main;
      font-size: 22px;
      font-weight: bold;
      color: $color_white;
      @include anime03;
      @include breakSP {
        max-width: 300px;
        height: 60px;
        font-size: 16px;
      }
      &::before {
        content: '';
        width: 20px;
        height: 14px;
        margin-right: 12px;
        background: url(../img/icon_mail.svg) no-repeat center center;
        background-size: 100%;
      }
      &:hover,
      &:focus {
        background-color: $color_white;
        color: $color_main;
        &::before {
          background: url(../img/icon_mail_black.svg) no-repeat center center;
          background-size: 100%;
        }
      }
    }
    .page-home &_btn {
      background-color: $color_white;
      border: solid 1px $color_main;
      color: $color_main;
      &::before {
        content: '';
        width: 20px;
        height: 14px;
        margin-right: 12px;
        background: url(../img/icon_mail_black.svg) no-repeat center center;
        background-size: 100%;
      }
      &:hover,
      &:focus {
        background-color: $color_main;
        color: $color_white;
        &::before {
          background: url(../img/icon_mail.svg) no-repeat center center;
          background-size: 100%;
        }
      }
    }
    &_phone {
      .title {
        margin-bottom: 10px;
        font-size: 22px;
        font-weight: bold;
        color: $color_main;
        @include breakSP {
          font-size: 16px;
        }
      }
      a {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom: 15px;
        .en {
          font-size: 40px;
          font-style: italic;
          font-weight: 300;
          line-height: 1;
          letter-spacing: 0.08em;
          @include breakSP {
            font-size: 20px;
          }
        }
        .number {
          @include fontfam-en;
          font-size: 50px;
          font-style: italic;
          font-weight: 300;
          line-height: 1;
          @include breakSP {
            font-size: 32px;
          }
        }
      }
      .text {
        font-size: 15px;
        color: $color_main;
        @include breakSP {
          font-size: 11px;
        }
      }
    }
  }
  .inner {
    margin-bottom: 20px;
    padding: 36px 0;
    background-color: $color_gray;
    @include breakSP {
      padding: 23px 0 16px;
    }
  }
  &_logo {
    width: 168px;
    margin: 0 auto 26px;
    @include breakSP {
      width: 120px;
      margin: 0 auto 10px;
    }
  } 
  &-address {
    margin-bottom: 30px;
    font-size: 15px;
    @include breakSP {
      margin-bottom: 10px;
      font-size: 11px;
    }
    .main {
      font-weight: bold;
    }
  }
  .sns_link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    .list_item {
      width: 19px;
      margin-right: 20px;
      @include link_opacity;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_copyright {
    padding-bottom: 10px;
    @include fontfam-en;
    font-size: 12px;
    text-align: center;
    @include breakSP {
      font-size: 8px;
    }
  }
}



// page top -- common
///////////////////////////////////////
.page_top {
  width: 76px;
  margin: 0 auto;
  text-align: center;
  @include breakSP {
    width: 50px;
  }
  a {
    display: block;
  }
}


