// solution -- solution page
///////////////////////////////////////
.solution {
  &-link_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .list_item {
      width: 47%;
      margin-bottom: 10px;
      @include breakSP {
        width: 100%;
      }
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 10px;
      background-color: $color_white;
      border: solid 5px $color_main;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0.05em;
      text-align: center;
      color: $color_main;
      @include breakSP {
        font-size: 15px;
      }
      &:hover {
        background-color: $color_main;
        color: $color_white;
      }
    }
    .title {
      position: relative;
      padding-left: 35px;
      @include breakSP {
        padding-left: 25px;
      }
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 24px;
        height: 24px;
        transform: translateY(-50%);
        background: url(../img/icon_pdf.png) no-repeat center center;
        background-size: 100%;
        @include breakSP {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}