// entry,contact -- form page
///////////////////////////////////////
.member {
  .inner {
    @include baseOverWrap {
      padding: 0 15px;
    }
  }
  &--form {
    .form_section {
      margin-bottom: 60px;
      @include breakSP {
        margin-bottom: 30px;
      }
    }
    .read_text {
      font-size: 15px;
      letter-spacing: 0.05em;
      @include breakSP {
        font-size: 13px;
      }
      .bold {
        display: block;
        padding-top: 15px;
        font-size: 120%;
        font-weight: bold;
      }
    }
    .message-box {
      margin-top: 40px;
    }
    .alert-message {
      padding: 5px 10px;
      background-color: $color_red;
      color: $color_white;
      @include breakSP {
        font-size: 13px;
      }
    }
    .form {
      &__select {
        position: relative;
        display: inline-block;
        width: 180px;
        border: solid 1px $color_light_blue;
        border-radius: 3px;
        font-size: 15px;
        line-height: 1.4;
        @include breakSP {
          width: 49%;
          border-radius: 3px;
          font-size: 13px;
        }
        &::after {
          content: '';
          position: absolute;
          top: 40%;
          right: 17px;
          transform: translateY(-50%);
          bottom: 0;
          display: block;
          width: 9px;
          height: 9px;
          border-right: 1px solid $color_text;
          border-bottom: 1px solid $color_text;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          pointer-events: none;
          @include breakSP {
            right: 10px;
            width: 7px;
            height: 7px;
          }
        }
        select {
          width: 100%;
        }
        + .form__select {
          margin-left: 20px;
          @include breakSP {
            margin-left: 2%;
          }
        }
      }
      &__radio {
        position: relative;
        display: inline-block;
        margin-left: 20px;
        font-size: 15px;
        line-height: 1.4;
        @include breakSP {
          font-size: 13px;
        }
        &:nth-of-type(odd) {
          margin-left: 0;
        }
        &:nth-last-of-type(1) {
          .radio_label {
            margin-bottom: 0;
          }
        }
        .radio_label {
          position: relative;
          display: block;
          margin-bottom: 20px;
          padding-left: 43px;
          cursor: pointer;
          @include breakSP {
            padding-left: 34px;
          }
          &::before {
            content: '';
            position: absolute;
            top: 52%;
            left: 0;
            transform: translateY(-50%);
            display: block;
            width: 30px;
            height: 30px;
            background-color: $color_gray;
            border: solid 1px $color_light_blue;
            border-radius: 50px;
            @include breakSP {
              width: 26px;
              height: 26px;
            }
          }
          &::after {
            opacity: 0;
            content: '';
            position: absolute;
            top: 52%;
            left: 10px;
            transform: translateY(-50%);
            bottom: 0;
            display: block;
            width: 10px;
            height: 10px;
            background-color: $color_text;
            border-radius: 50px;
            @include breakSP {
              left: 9px;
              width: 8px;
              height: 8px;
            }
          }
        }
        input[type="radio"] {
          display: none;
        }
        input[type="radio"]:checked + .radio_label {
          &::after {
            opacity: 1;
          }
        }
      }
      &__block {
        display: flex;
        align-items: flex-start;
        border-bottom: solid 1px $color_light_blue;
        &:first-of-type {
          margin-top: 68px;
          @include breakSP {
            margin-top: 30px;
            .form__block--head {
              padding-top: 0;
            }
          }
        }
        @include breakSP {
          display: block;
          width: 100%;
        }
        &--head {
          display: flex;
          align-items: center;
          width: 300px;
          padding: 20px 30px;
          @include breakSP {
            width: 100%;
            padding: 15px 0 10px;
          }
          .label {
            margin-right: 10px;
            font-size: 15px;
            font-weight: bold;
            color: $color_text;
            @include breakSP {
              font-size: 13px;
            }
          }
          .required {
            padding: 0 6px;
            background-color: $color_link;
            font-size: 10px;
            color: $color_white;
            @include breakSP {
              padding: 0 6px 2px 6px;
            }
          }
        }
        &--body {
          display: flex;
          align-items: center;
          flex-direction: row;
          flex-wrap: wrap;
          width: calc( 100% - 300px);
          padding: 30px 10px 30px 0;
          @include breakSP {
            width: 100%;
            padding: 0 0 15px;
          }
          input[type="radio"] {
            @include breakSP {
              width: 16px;
              height: 16px;
              margin: 0;
              padding: 0;
            }
          }
          textarea {
            padding: 10px;
            border-radius: 3px;
            @include breakSP {
              font-size: 13px;
            }
          }
          .-error {
            background-color: rgba($color: $color_red, $alpha: .1);
            border: solid 1px $color_red;
          }
          .bs-mail-description {
            margin-bottom: 10px;
            @include breakSP {
              font-size: 12px;
            }
          }
          > span {
            display: block;
          }
        }
        .form__error {
          display: block;
          width: 100%;
          margin-top: 5px;
          color: $color_red;
          @include breakSP {
            font-size: 12px;
          }
        }
        &--radio {
          display: flex;
          flex-direction: row;
          .form__radio {
            display: flex;
            align-items: center;
            margin-right: 20px;
            label {
              padding-left: 5px;
            }
          }
        }
        &--recruitment_category {
          align-items: center;
          .radio_label {
            margin-bottom: 0;
          }
        }
        &--name {
          align-items: center;
          .meta {
            display: inline-block;
            width: 2em;
            margin: 0 10px 0 20px;
            font-size: 15px;
            text-align: center;
            @include breakSP {
              margin: 0 5px 0 0;
              font-size: 12px;
            }
            &:first-child {
              margin-left: 0;
            }
          }
        }
        &--kana {
          align-items: center;
          .meta {
            display: inline-block;
            width: 2em;
            margin: 0 10px 0 20px;
            font-size: 15px;
            text-align: center;
            @include breakSP {
              margin: 0 5px 0 0;
              font-size: 12px;
            }
            &:first-child {
              margin-left: 0;
            }
          }
        }
        &--gender {
          align-items: center;
          .radio_label {
            margin-bottom: 0;
          }
        }
        &--birth {
          align-items: center;
          .w30 {
            width: 28%;
            max-width: 150px;
            @include breakSP {
              width: 26%;
            }
          }
          .meta {
            display: inline-block;
            margin: 0 10px;
            @include breakSP {
              margin: 0 5px;
              font-size: 10px;
            }
          }
        }
        &--school {
          align-items: center;
        }
        &--mail {
          align-items: center;
        }
        &--mail_confirm {
          align-items: center;
        }
        &--history {
          align-items: center;
        }
        &--phone {
          align-items: center;
          .w30 {
            width: 30%;
            max-width: 150px;
            @include breakSP {
              width: 29%;
            }
          }
          .meta {
            display: inline-block;
            margin: 0 10px;
            @include breakSP {
              margin: 0 5px;
              font-size: 12px;
            }
          }
        }
        &--entry_type {
          align-items: center;
          .form__radio {
            width: 40%;
            &:nth-of-type(3) {
              .radio_label {
                margin-bottom: 0;
              }
            }
          }
        }
        &--company_name {
          align-items: center;
          .meta {
            display: inline-block;
            width: 2em;
            margin: 0 10px 0 20px;
            font-size: 15px;
            text-align: center;
            @include breakSP {
              margin: 0 5px 0 0;
              font-size: 12px;
            }
            &:first-child {
              margin-left: 0;
            }
          }
        }
        &--address {
          .meta {
            display: inline-block;
            margin: 0 10px;
            font-size: 15px;
          }
          .form__block--body {
            align-items: flex-start;
            > span:nth-of-type(1) {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              width: 50%;
              @include breakSP {
                width: 100%;
              }
              .bs-mail-before-attachment {
                width: 100%;
              }
            }
            > span:nth-of-type(2) {
              width: 40%;
              @include breakSP {
                width: 100%;
                margin-top: 20px;
              }
            }
            > span:nth-of-type(3) {
              width: 100%;
              margin-top: 20px;
            }
            > span:nth-of-type(4) {
              width: 100%;
              margin-top: 20px;
            }
          }
          .w40 {
            width: 40%;
          }
          .bs-mail-before-attachment {
            display: block;
          }
          .member_confirm & {
            align-items: center;
          }
        }
        &--inquiry_type {
          .member_confirm & {
            align-items: center;
          }
        }
        &--inquiry_text {
          .member_confirm & {
            align-items: center;
          }
        }
        &--attachment {
          align-items: flex-start;
          flex-direction: column;
          .member_confirm & {
            align-items: center;
            flex-direction: row;
            @include breakSP {
              flex-direction: column;
            }
          }
          .attachment_block {
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: 10px;
            input[type="file"] {
              display: none;
            }
            .member_confirm & {
              width: 42%;
              max-width: 300px;
              margin-bottom: 20px;
              @include breakSP {
                width: 100%;
                max-width: 250px;
              }
              + .attachment_block {
                margin-left: 20px;
                @include breakSP {
                  margin-left: 0;
                }
              }
            }
          }
          .attachment_text {
            overflow: hidden;
            width: calc( 100% - 260px);
            text-overflow: ellipsis;
            white-space: nowrap;
            @include breakSP {
              width: 120px;
              font-size: 12px;
            }
          }
          .attachment_delete {
            display: none;
            width: 20px;
            margin-left: 10px;
            cursor: pointer;
            @include breakSP {
              width: 30px;
              margin-left: 5px;
            }
          }
          .confirm_img {
            width: 100%;
            max-width: 280px;
            min-height: 200px;
            object-fit: contain;
            padding: 20px;
            background-color: #f6f7f7;
          }
        }
      }
    }
  }
  .form__button {
    margin-top: 40px;
    text-align: center;
    @include breakSP {
      margin-top: 30px;
    }
    .agreement_check {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 700px;
      height: 50px;
      margin: 0 auto 60px;
      padding: 0 15px;
      background-color: $color_gray;
      font-size: 15px;
      font-weight: 500;
      @include breakSP {
        height: 60px;
        margin-bottom: 30px;
        font-size: 13px;
      }
      .agreement_check-box {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        position: relative;
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-color: $color_white;
        border: solid 1px $color_light_blue;
        border-radius: 3px;
        cursor: pointer;
        &:checked {
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: 16px;
            height: 16px;
            background: url(../img/icon_check.svg) no-repeat center center;
            background-size: 100%;
            @include breakSP {
              width: 14px;
              height: 14px;
            }
          }
        }
      }
      label {
        cursor: pointer;
        @include breakSP {
          width: calc( 100% - 30px );
        }
      }
      a {
        text-decoration: underline;
        color: $color_text;
        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
    .button__item {
      display: block;
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      @include breakSP {
        width: 70%;
        max-width: 250px;
      }
      button {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        display: block;
        width: 100%;
        margin: 0 auto;
        padding: 34px 0;
        background-color: $color_white;
        border: solid 5px $color_main;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
        line-height: 1;
        color: $color_main_dark;
        cursor: pointer;
        @include anime03;
        @include breakSP {
          padding: 20px 0;
          font-size: 13px;
        }
        &:hover {
          background-color: $color_main;
          color: $color_white;
        }
        &:disabled {
          background-color: #dcdddd;
          border: solid 2px #dcdddd;
          color: $color_white;
          cursor: auto;
          &:hover {
            opacity: 1;
          }
        }
      }
      &.-return {
        max-width: 200px;
        @include breakSP {
          max-width: 150px;
        }
      }
    }
    &.button_list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
      @include breakSP {
        flex-direction: column;
      }
      .button__item {
        margin: 0;
        + .button__item {
          margin-right: 20px;
          @include breakSP {
            margin-top: 20px;
            margin-right: 0;
          }
        }
      }
    }
  }
  .confirm_text {
    font-size: 15px;
    @include breakSP {
      font-size: 13px;
    }
  }
  .forget_link {
    margin-top: 10px;
    text-align: right;
    .text_link {
      position: relative;
      @include breakSP {
        font-size: 13px;
        text-decoration: underline;
      }
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: -3px;
        width: 0;
        height: 2px;
        background-color: $color_main;
        @include anime03;
      }
      &:hover {
        &::before {
          width: 100%;
        }
      }
    }
  }
  .forget_text {
    margin-bottom: 40px;
    @include breakSP {
      margin-bottom: 20px;
      font-size: 13px;
    }
  }
  &_entry {
    &--read {
      margin-bottom: 80px;
      text-align: center;
      @include breakSP {
        margin-bottom: 45px;
      }
      .img {
        width: 100%;
        max-width: 780px;
        margin: 0 auto 60px;
        @include breakSP {
          margin-bottom: 30px;
        }
      }
      .welcome_message {
        width: 100%;
        max-width: 417px;
        margin: 0 auto 40px;
        @include breakSP {
          max-width: 263px;
          margin-bottom: 20px;
        }
      }
      .text {
        font-size: 16px;
        font-weight: 400;
        line-height: 2.1;
        letter-spacing: 0.1em;
        @include breakSP {
          font-size: 13px;
        }
      }
    }
  }
  &_complete {
    &--read {
      margin-top: 75px;
      text-align: center;
      .img {
        width: 193px;
        margin: 0 auto 40px;
        @include breakSP {
          width: 150px;
          margin: 0 auto 30px;
        }
      }
      .title {
        margin-bottom: 37px;
        font-size: 50px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: 0.1em;
        @include breakSP {
          margin-bottom: 20px;
          font-size: 30px;
        }
      }
      .text {
        font-size: 20px;
        font-weight: 500;
        line-height: 1.8;
        letter-spacing: 0.1em;
        @include breakSP {
          font-size: 15px;
        }
      }
    }
  }
}
