// home -- top page
///////////////////////////////////////
.home {
  @keyframes mv_bg {
    0% {
      opacity: 0;
    }
    8% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    60% {
      opacity: 0;
      transform: scale(1.1);
      z-index:9;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes mv_fuwafuwa {
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(0, -6px);
    }
    100% {
      transform: translate(0, 0);
    }
  }  
  .mv {
    position: relative;
    width: 100%;
    height: 100vh;
    @include breakSP {
      height: 450px;
    }
    &__back {
      overflow: hidden;
      position: relative;
      height: 100vh;
      @include breakSP {
        height: 450px;
      }
      &-img {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        -webkit-animation: mv_bg 18s 0s infinite;
        animation: mv_bg 18s 0s infinite;
        z-index: 10;
      }
      &-img01 {
        background: url(../img/home/fv_img01.png) no-repeat center center;
        background-size: cover;
      }
      &-img02 {
        background: url(../img/home/fv_img02.png) no-repeat center center;
        background-size: cover;
        -webkit-animation-delay: 6s;
        animation-delay: 6s;
      }
      &-img03 {
        background: url(../img/home/fv_img03.png) no-repeat center center;
        background-size: cover;
        -webkit-animation-delay: 12s;
        animation-delay: 12s;
      }
    }
    .wrap {
      position: absolute;
      top: 0;
      left: 50%;
      height: 100vh;
      transform: translateX(-50%);
      z-index: 100;
      @include breakSP {
        height: 450px;
      }
    }
    &__title {
      position: absolute;
      top: 60%;
      left: 0;
      max-width: $width_base;
      margin: 0 auto;
      font-size: 50px;
      font-weight: bold;
      letter-spacing: 0.5em;
      color: $color_main;
      z-index: 100;
      @include anime03;
      @include breakPC {
        left: 15px;
        font-size: 5vw;
      }
      @include breakSP {
        top: 40%;
        left: 20%;
        font-size: 25px;
        letter-spacing: 0.4em;
      }
    }
    &__catchcopy {
      position: absolute;
      top: 70%;
      left: 100px;
      margin: 0 auto;
      font-size: 22px;
      font-weight: bold;
      line-height: 2.1;
      letter-spacing: 0.08em;
      color: $color_main;
      z-index: 100;
      @include anime03;
      @include breakPC {
        font-size: 2vw;
      }
      @include breakSP {
        top: 50%;
        left: 34%;
        font-size: 11px;
      }
    }
    &__scroll {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 101;
      @include breakSP {
        bottom: 15px;
      }
      a {
        display: block;
        text-align: center;
      }
      &-img {
        width: 73px;
        -webkit-animation: mv_fuwafuwa 2s infinite linear alternate;
        animation: mv_fuwafuwa 2s infinite linear alternate;
        @include breakSP {
          width: 36px;
        }
      }
      &-text {
        @include fontfam-en_roboto;
        font-size: 13px;
        letter-spacing: 0.1em;
        @include breakSP {
          font-size: 9px;
        }
      }
    }
  }
  &-company {
    width: 100%;
    margin-bottom: 80px;
    padding: 60px 0;
    background: url(../img/home/img01.png) no-repeat center center;
    background-size: cover;
    @include breakSP {
      margin-bottom: 40px;
    }
    .home-section_text {
      text-align: center;
    }
  }
  &-linkBox {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .list_item {
      width: calc( 100% / 3 );
      max-width: 320px;
      margin-right: 20px;
      margin-bottom: 20px;
      @include breakSP {
        width: 160px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 180px;
        background-color: $color_white;
        border: solid 5px $color_main;
        color: $color_main_dark;
        @include breakSP {
          height: 90px;
          border: solid 2px $color_main;
        }
        &:hover,
        &:focus {
          background-color: $color_main;
          color: $color_white;
          .ja {
            &::before,
            &::after {
              background-color: $color_white;
            }
          }
        }
        .ja {
          position: relative;
          padding-bottom: 40px;
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 0.05em;
          @include breakSP {
            padding-bottom: 20px;
            font-size: 16px;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 15px;
            left: 50%;
            transform: translateX(-50%);
            width: 41px;
            height: 1px;
            background-color: $color_main;
            @include breakSP {
              bottom: 10px;
              width: 21px;
            }
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 20px;
            left: calc( 50% + 15px );
            transform: translateX(-50%) rotate(45deg);
            width: 15px;
            height: 1px;
            background-color: $color_main;
            @include breakSP {
              bottom: 13px;
              width: 8px;
              left: calc( 50% + 8px );
            }
          }
        }
      }
    }
  }
  &-solution {
    margin-bottom: 80px;
    background: url(../img/home/img02.png) no-repeat right center;
    background-size: 58% auto;
    @include breakSP {
      margin-bottom: 40px;
      background-position: center bottom;
      background-size: 100%;
    }
    .wrap {
      padding: 80px 0 100px;
      @include breakPC {
        padding: 0 15px 50px;
      }
    }
    .home-section_title {
      text-align: left;
      &::after {
        left: 0;
        transform: translateX(0);
      }
    }
    .home-section_text {
      max-width: 350px;
      text-align: left;
      color: $color_text;
    }
    .home-linkBox {
      margin-bottom: 18px;
      a {
        @include breakPC {
          height: 120px;
        }
      }
    }
    &_link {
      display: block;
      max-width: 500px;
      margin: 0 auto;
      padding: 20px 0;
      background-color: $color_white;
      border: solid 1px $color_main;
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      text-align: center;
      color: $color_main_dark;
      &:hover,
      &:focus {
        background-color: $color_main;
        color: $color_white;
      }
    }
  }
  &-recruit {
    position: relative;
    padding: 80px 0 103px;
    background: url(../img/home/img03.png) no-repeat left top;
    background-size: 58% auto;
    z-index: 10;
    @include breakSP {
      padding: 0 0 103px;
      background-position: center bottom;
      background-size: 100%;
    }
    ._inner {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      align-items: flex-end;
      @include breakSP {
        display: block;
      }
    }
    .home-section_title {
      min-width: 350px;
      text-align: left;
      &::after {
        left: 0;
        transform: translateX(0);
      }
    }
    .home-section_text {
      max-width: 350px;
      margin-bottom: 60px;
      text-align: left;
      color: $color_text;
    }
  }
  &-recruit_news {
    position: relative;
    margin-top: -60px;
    margin-bottom: 80px;
    background-color: $color_gray;
    z-index: 1;
    @include breakSP {
      margin-top: 0;
    }
  }
  .news_section {
    display: flex;
    justify-content: space-between;
    padding: 55px 50px 45px;
    @include breakSP {
      display: block;
      padding: 25px 15px;
    }
    &--title {
      width: 20%;
      color: $color_main;
      white-space: nowrap;
      @include breakSP {
        width: 100%;
        margin-bottom: 40px;
        padding-top: 0;
      }
      .en {
        display: block;
        margin-bottom: 10px;
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.2em;
      }
      .ja {
        display: block;
        font-size: 13px;
        font-weight: bold;
        letter-spacing: 0.01em;
      }
    }
    &--detail {
      width: 70%;
      max-width: 680px;
      @include breakSP {
        width: 100%;
        max-width: 100%;
      }
    }
    &--list {
      border-top: dotted 1px #bfbfbf;
      .list_item {
        display: flex;
        padding: 18px 0;
        border-bottom: dotted 1px #bfbfbf;
        .day {
          display: block;
          margin-right: 20px;
          @include fontfam-en;
          font-size: 14px;
          letter-spacing: 1;
          white-space: nowrap;
          color: $color_text;
        }
        a {
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          color: $color_text;
          &:hover,
          &:focus {
            text-decoration: underline;
            color: $color_link;
          }
        }
      }
    }
    &--link {
      display: block;
      margin-top: 15px;
      font-weight: bold;
      text-align: right;
      color: $color_text;
      .ja {
        position: relative;
        &::before {
          content: '';
          position: absolute;
          top: 70%;
          left: -30px;
          transform: translateY(-50%);
          width: 21px;
          height: 1px;
          background-color: $color_text;
          @include anime03;
          @include breakSP {
            bottom: 10px;
            width: 21px;
          }
        }
        &::after {
          content: '';
          position: absolute;
          top: 44%;
          left: -18px;
          transform: translateY(-50%) rotate(45deg);
          width: 10px;
          height: 1px;
          background-color: $color_text;
          @include breakSP {
            top: 6px;
            left: -16px;
            width: 8px;
          }
        }
      }
      &:hover,
      &:focus {
        color: $color_link;
        .ja {
          &::before {
            left: -39px;
            width: 30px;
            background-color: $color_link;
          }
          &::after {
            background-color: $color_link;
          }
        }
      }
    }
  }
  &-sns {
    margin-bottom: 70px;
    @include breakSP {
      margin-bottom: 40px;
    }
    &_list {
      display: flex;
      justify-content: space-between;
      @include breakSP {
        display: block;
      }
      .title {
        margin-bottom: 20px;
        font-size: 20px;
        letter-spacing: 0.2em;
        color: $color_main;
      }
      .list_item {
        width: 30%;
        @include breakSP {
          width: 90%;
          margin: 0 auto 30px;
        }
      }
      #twitter-widget-0 {
        .timeline-Body {
          overflow-y: scroll;
          max-height: 400px;
        }
      }
    }
  }
  &-news {
    padding: 55px 0 60px;
    background-color: $color_gray;
    .wrap {
      display: flex;
      justify-content: space-between;
      @include breakSP {
        display: block;
      }
    }
    .news_section--title {
      margin-right: 40px;
      padding-top: 20px;
      white-space: nowrap;
      @include breakSP {
        margin-bottom: 40px;
        padding-top: 0;
      }
    }
    .news_section--detail {
      max-width: 730px;
    }
  }
}



.home-section_title {
  position: relative;
  margin-bottom: 50px;
  text-align: center;
  color: $color_main;
  &::after {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 50%;
    transform: translateX(-50%);
    width: 55px;
    height: 5px;
    background-color: $color_blue;
  }
  .en {
    display: block;
    margin-bottom: 25px;
    font-size: 50px;
    line-height: 1;
    letter-spacing: 0.2em;
    @include breakSP {
      margin-bottom: 10px;
      font-size: 40px;
      color: $color_text;
    }
  }
  .ja {
    display: block;
    font-size: 13px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: 0.1em;
    @include breakSP {
      color: $color_text;
    }
  }
}
.home-section_text {
  margin-bottom: 30px;
  font-size: 15px;
  line-height: 1.8;
  text-align: center;
  letter-spacing: 0.05em;
  color: $color_main;
  @include breakSP {
    font-size: 13px;
  }
}
