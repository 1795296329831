.pager {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 20px;
  @include link_opacity;
  .pager_item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    margin: 0 5px;
    @include fontfam-en;
    background-color: $color_white;
    border-radius: 50px;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: $color_main;
    @include breakSP {
      margin: 0 3px;
      font-size: 12px;
    }
    &[data-active="true"] {
      background-color: #e6f1f7;
      font-weight: 700;
      &:hover,
      &:focus {
        opacity: 1;
        cursor: unset;
      }
    }
  }
  .pager_prev,
  .pager_next {
    position: relative;
    display: inline-block;
    @include fontfam-en;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.05em;
    text-align: center;
    color: $color_main;
    @include anime03;
    @include breakSP {
      font-size: 13px;
    }
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
  .pager_prev {
    margin: 0 10px 0 0;
    padding-right: 25px;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 40px;
      background-color: $color_main;
    }
  }
  .pager_next {
    margin: 0 0 0 10px;
    padding-left: 25px;
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 40px;
      background-color: $color_main;
    }
  }
  .pager_gap {
    display: block;
    margin: -0.5em 5px 0;
    font-size: 15px;
    white-space: nowrap;
    color: $color_main;
    @include breakSP {
      margin: -0.5em 3px 0;
    }
  }
}


/* burger style */
.bge-contents {
  [data-bgb] h2 {
    @include parts-section_title;
    margin-top: 40px;
  }
  [data-bgb] h3 {
    @include parts-section_detail_title;
    margin-top: 40px;
  }
  [data-bgb] h4,
  [data-bgb] h5 {
    margin-bottom: 20px;
    font-size: 1.2em;
    font-weight: 500;
  }
  [data-bgb] ul,
  [data-bgb] ol {
    margin-bottom: 20px;
    padding-left: 3em;
    @include breakSP {
      font-size: 13px;
    }
  }
  [data-bgb] ul li {
    list-style-type: disc;
    margin-bottom: 10px;
  }
  [data-bgb] ol li {
    list-style-type: decimal;
    margin-bottom: 10px;
  }


  .bgb-image-text1 p,
  .bgb-image-text2 p,
  .bgb-image-text3 p,
  .bgb-image-text4 p,
  .bgb-image-text5 p {
    font-size: 13px;
    line-height: 1.4;
  }
  .bgb-image-text1 p:first-child,
  .bgb-image-text2 p:first-child,
  .bgb-image-text3 p:first-child,
  .bgb-image-text4 p:first-child,
  .bgb-image-text5 p:first-child {
    margin-top: 5px;
  }
  [data-bgb="image-link-text4"] .bgt-grid:nth-child(1), [data-bgb="image-link4"] .bgt-grid:nth-child(1), [data-bgb="image-text4"] .bgt-grid:nth-child(1), [data-bgb="image4"] .bgt-grid:nth-child(1), [data-bgb="trimmed-image-link4"] .bgt-grid:nth-child(1), [data-bgb="trimmed-image4"] .bgt-grid:nth-child(1) {
    padding-right: 20px;
  }


  p {
    margin-bottom: 40px;
    line-height: 2;
    letter-spacing: 0.05em;
    @include breakSP {
      margin-bottom: 30px;
      font-size: 13px;
    }
  }
  p a {
    line-height: 2;
    text-decoration: underline;
    letter-spacing: 0.05em;
  }
  strong {
    font-weight: bold;
  }
  .text_small {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    @include breakSP {
      padding: 0 25px;
    }
  }
  .text_small .bgt-grid--first, 
  .text_small .bgt-grid--last {
    float: none;
    line-height: 2;
  }
  .text_small .bge-ckeditor {
    width: 380px;
    @include breakSP {
      width: 100%;
    }
  }
  .text_small .bgt-grid--first .bge-ckeditor {
    margin: 0 0 0 auto;
  }
  
  .more_link {
    margin-bottom: 4em;
    text-align: left;
    @include link_opacity;
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .box_text {
    max-width: 700px;
    margin: 0 auto 40px;
    padding: 25px 30px;
    background: $color_white;
    border: solid 5px $color_main;
    p:last-child {
      margin-bottom: 0;
    }
  }
  .attention_block {
    max-width: 700px;
    margin: 0 auto 40px;
    padding: 25px 30px;
    background: $color_white;
    border: solid 1px $color_main;
    font-size: 13px;
    p:last-child {
      margin-bottom: 0;
    }
  }
  .bgb-button {
    position: relative;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      height: 60px;
      overflow: hidden;
      width: 400px;
      background-color: $color_white;
      border: solid 2px $color_main;
      border-radius: 60px;
      text-align: center;
      font-size: 18px;
      @include anime05;
      color: $color_main;
      &[href]:hover {
        background-color: $color_main;
        color: $color_white;
        @include anime05;
      }
      @include breakSP {
        width: 80%;
        height: 52px;
        font-size: 15px;
        line-height: 42px;
      }
    }
    span {
      z-index: 5;
    }
  }
  
  table {
    max-width: 800px;
    margin: 0 auto 50px;
    tbody {
      background-color: $color_white;
    }
    caption {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
      text-align: left;
      @include breakSP {
        font-size: 16px;
      }
    }
    tr {
      border-bottom: solid 1px #eeeeee;
      &:first-child {
        border-top: solid 1px #eeeeee;
      }
    }
    th {
      padding: 20px;
      background-color: rgba($color: $color_gray, $alpha: .1);
      font-weight: bold;
      @include breakSP {
        padding: 10px;
      }
    }
    td {
      padding: 20px;
      @include breakSP {
        padding: 10px;
      }
    }
  }


  .bge-contents .bgt-btn--link, .bge-contents [data-bgt-button-kind="link"] .bgt-btn {
    border: solid 2px $color_main;
    font-weight: bold;
    color: $color_main;
  }
  [data-bgb] table tr {
    border-bottom: solid 1px #eeeeee;
    @include breakSP {
      margin-top: 0;
    }
  }
  [data-bgb] table tr th {
    padding: 20px;
    background-color: rgba($color: $color_gray, $alpha: .1);
    border: none;
    @include breakSP {
      padding: 15px;
    }
  }
  [data-bgb] table tr td {
    padding: 20px;
    background-color: $color_white;
    border: none;
    @include breakSP {
      padding: 15px;
    }
  }
  [data-bgb] table tr:nth-child(2n) td {
    background-color: $color_white;
  }
}


// blog -- news page
///////////////////////////////////////
.blog {
  &_list {
    margin-bottom: 40px;
  }
  &_item {
    margin-bottom: 80px;
    @include breakSP {
      margin-bottom: 40px;
    }
    &-head {
      display: flex;
      margin-bottom: 26px;
      &__day {
        display: block;
        width: 150px;
        height: 30px;
        padding: 7px 0 8px;
        background-color: $color_gray;
        @include fontfam-en;
        font-size: 15px;
        line-height: 1;
        text-align: center;
        letter-spacing: 0.1em;
        @include breakSP {
          width: 100px;
          height: 26px;
          font-size: 12px;
        }
      }
      &__category {
        display: block;
        min-width: 150px;
        height: 30px;
        padding: 7px 10px 8px;
        background-color: #e6f1f7;
        @include fontfam-en;
        font-size: 15px;
        line-height: 1;
        text-align: center;
        letter-spacing: 0.1em;
        color: $color_main;
        @include breakSP {
          min-width: 100px;
          height: 26px;
          font-size: 12px;
        }
      }
    }
    &-title {
      margin-bottom: 30px;
      padding-bottom: 16px;
      border-bottom: solid 2px $color_light_blue;
      font-size: 32px;
      font-weight: bold;
      line-height: 1.3;
      letter-spacing: 0.1em;
      color: $color_main;
      @include breakSP {
        margin-bottom: 20px;
        font-size: 20px;
      }
    }
    &-body {
      padding: 0 50px;
      @include breakSP {
        padding: 0 25px;
      }
    }
  }
}