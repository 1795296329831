// company -- company page
///////////////////////////////////////
.company {
  .parts-table_list {
    .en {
      font-weight: 400;
    }
  }
  &-message {
    display: flex;
    justify-content: space-between;
    @include breakSP {
      display: block;
    }
    &_img {
      width: 30%;
      max-width: 220px;
      @include breakSP {
        width: 60%;
        margin: 0 auto;
      }
    }
    &_text {
      width: 69%;
      max-width: calc( 100% - 250px );
      @include breakSP {
        width: 100%;
        max-width: 100%;
      }
      p {
        margin-bottom: 2em;
        line-height: 2;
      }
      .sign {
        font-weight: bold;
        text-align: right;
        .name {
          font-size: 20px;
          @include breakSP {
            font-size: 18px;
          }
        }
      }
    }
  }
  &-rinen_title {
    margin-bottom: 26px;
    font-size: 32px;
    font-weight: bold;
    line-height: 1.3;
    letter-spacing: 0.1em;
    text-align: center;
    color: $color_main;
    @include breakSP {
      font-size: 24px;
    }
  }
  &-rinen_img {
    max-width: 387px;
    margin: 0 auto;
    @include breakSP {
      margin: 0 auto 40px;
    }
  }
  &-rinen_contents {
    display: flex;
    margin-bottom: 40px;
    &:nth-of-type(1) {
      align-items: flex-end;
      @include breakSP {
        align-items: flex-start;
      }
    }
    &:nth-of-type(2) {
      margin-bottom: 20px;
    }
    .img {
      width: 130px;
      margin-right: 30px;
      @include breakSP {
        width: 60px;
        margin-right: 10px;
      }
    }
    .detail {
      width:  calc( 100% - 160px );
      @include breakSP {
        width:  calc( 100% - 80px );
      }
    }
    .title {
      margin-bottom: 16px;
      font-size: 26px;
      font-weight: bold;
      letter-spacing: 0.15em;
      @include breakSP {
        margin-bottom: 10px;
        font-size: 18px;
      }
    }
    .number_list {
      counter-reset: number_list-counter 0;
      > .list_item {
        display: flex;
        margin-bottom: 8px;
        &::before {
          counter-increment: number_list-counter 1;
          content: counter(number_list-counter) ".";
          width: 2em;
          @include fontfam-en;
        }
        p {
          width: calc( 100% - 2em );
        }
        dl {
          width: calc( 100% - 2em );
          dt {
            margin-bottom: 5px;
            font-weight: bold;
          }
        }
        .list {
          .list_item {
            position: relative;
            margin-bottom: 8px;
            padding-left: 14px;
            &::before {
              content: '';
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
              width: 6px;
              height: 6px;
              background-color: $color_text;
              border-radius: 10px;
              @include breakSP {
                top: 6px;
                transform: translateY(0);
              }
            }
          }
        }
      }
    }
  }
}
