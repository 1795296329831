// recruit -- recruit page
///////////////////////////////////////
.recruit {
  .section-detail_title {
    margin-bottom: 25px;
    @include breakSP {
      margin-bottom: 20px;
    }
  }
  .section-detail_text {
    @include link_opacity;
    a {
      text-decoration: underline;
      color: $color_text;
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
  &-flow {
    li {
      position: relative;
      margin-bottom: 16px;
      padding-bottom: 26px;
      padding-left: 0;
      font-weight: bold;
      @include breakSP {
        margin-bottom: 10px;
        padding-bottom: 20px;
      }
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 14px 9px 0 9px;
        border-color: $color_light_blue transparent transparent transparent;
      }
      &:last-of-type {
        margin-bottom: 0;
        &::after {
          border: none;
        }
      }
    }
  }
  &-entry_btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 500px;
    height: 80px;
    margin: 40px auto 0;
    background-color: $color_white;
    border: solid 5px $color_main;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    color: $color_main;
    @include breakSP {
      max-width: 300px;
      height: 60px;
      font-size: 16px;
    }
    &:hover,
    &:focus {
      background-color: $color_main;
      color: $color_white;
    }
    &::before {
      content: '';
      position: absolute;
      top: 56%;
      right: 15px;
      transform: translateY(-50%);
      width: 41px;
      height: 1px;
      background-color: $color_main;
      @include anime03;
      @include breakSP {
        right: 10px;
        width: 21px;
      }
    }
    &::after {
      content: '';
      position: absolute;
      top: calc( 56% + -5px );
      right: 12px;
      transform: translateY(-50%) rotate(45deg);
      width: 15px;
      height: 1px;
      background-color: $color_main;
      @include anime03;
      @include breakSP {
        top: calc( 56% + -3px );
        right: 8px;
        width: 9px;
      }
    }
    &:hover,
    &:focus {
      background-color: $color_main;
      color: $color_white;
      &::before {
        right: 9px;
        background-color: $color_white;
      }
      &::after {
        right: 6px;
        background-color: $color_white;
      }
    }
  }
  &-index_list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    margin-bottom: 60px;
    .list_item {
      width: calc( 100% / 3 );
      max-width: 320px;
      margin-right: 20px;
      margin-bottom: 20px;
      @include breakSP {
        width: 160px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
      &:last-child {
        margin-right: 0;
      }
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: $color_white;
        border: solid 5px $color_main;
        color: $color_main_dark;
        @include breakSP {
          padding-top: 10px;
          padding-bottom: 10px;
          border: solid 2px $color_main;
        }
        &:hover,
        &:focus {
          background-color: $color_main;
          color: $color_white;
          .ja {
            &::before,
            &::after {
              background-color: $color_white;
            }
          }
        }
        .ja {
          position: relative;
          padding-bottom: 40px;
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          letter-spacing: 0.05em;
          @include breakSP {
            padding-bottom: 20px;
            font-size: 16px;
          }
          &::before {
            content: '';
            position: absolute;
            bottom: 15px;
            left: 50%;
            transform: translateX(-50%);
            width: 41px;
            height: 1px;
            background-color: $color_main;
            @include breakSP {
              bottom: 10px;
              width: 21px;
            }
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 20px;
            left: calc( 50% + 15px );
            transform: translateX(-50%) rotate(45deg);
            width: 15px;
            height: 1px;
            background-color: $color_main;
            @include breakSP {
              bottom: 13px;
              width: 8px;
              left: calc( 50% + 8px );
            }
          }
        }
      }
    }
  }
  &-index_img {
    max-width: 80%;
    margin-inline: auto;
    margin-bottom: 40px;
    text-align: center;
    @include breakSP {
      max-width: 100%;
      margin-bottom: 20px;
    }
  }
}
