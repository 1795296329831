$width_base_overWrap_pa: 1230px;
$width_base_overWrap: 1200px;
$width_base_over: 1001px;
$width_base: 1000px;
$width_tab: 960px;
$width_sp: 768px;
@mixin baseOverWrap_over_pa {
  @media only screen and (max-width: $width_base_overWrap_pa) {
    @content;
  }
}
// 1200px以上全部
@mixin baseOverWrap_over {
  @media only screen and (min-width: $width_base_overWrap) {
    @content;
  }
}
// 1200px以下全部
@mixin baseOverWrap_over_under {
  @media only screen and (max-width: $width_base_overWrap) {
    @content;
  }
}
// 1001px以上全部
@mixin baseOverWrap {
  @media only screen and (min-width: $width_base_over) {
    @content;
  }
}
// 1000px以下全部
@mixin breakPC {
  @media only screen and (max-width: $width_base) {
    @content;
  }
}
// 960px以下全部
@mixin breakTAB {
  @media only screen and (max-width: $width_tab) {
    @content;
  }
}
// 768px以下全部
@mixin breakSP {
  @media only screen and (max-width: $width_sp) {
    @content;
  }
}


// IE11用
@mixin hack_ie11 {
  @at-root {
    @media all and (-ms-high-contrast: none) {
      *::-ms-backdrop, & {
        @content;
      }
    }
  }
}


// font
///////////////////////////////////////
@mixin fontfam-ja {
  font-family: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif;
  font-weight: 400;
}
@mixin fontfam-en {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
@mixin fontfam-en_roboto {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
}


@mixin overIMG {
	img {
		transition: -webkit-transform 400ms;
		transition: -ms-transform 400ms;
		transition: -moz-transform 400ms;
		transition: transform 400ms;
	}
	&:hover {
		img {
			-webkit-transform: scale(1.1);
			-ms-transform: scale(1.1);
			-moz-transform: scale(1.1);
			transform: scale(1.1);
		}
	}
}
@mixin anime03 {
	transition: all 0.3s 0s ease;
	-webkit-transition: all 0.3s 0s ease;
	-moz-transition: all 0.3s 0s ease;
	-o-transition: all 0.3s 0s ease;
}
@mixin anime05 {
	transition: all 0.5s 0s ease;
	-webkit-transition: all 0.5s 0s ease;
	-moz-transition: all 0.5s 0s ease;
	-o-transition: all 0.5s 0s ease;
}
@mixin input_placeholder($color_input) {
	&::placeholder {color: $color_input;}
	&:-ms-input-placeholder {color: $color_input;}
	&::-ms-input-placeholder {color: $color_input;}
}


$color_main: #0b0353;
$color_main_dark: #001b3e;
$color_sub: #78a7d3;
$color_blue: #e2f4fd;
$color_light_blue: #ccd1d8;
$color_red: #de0000;
$color_white: #fff;
$color_gray: #f6f6f6;
$color_text: #3b3b3b;
$color_link: #0061de;


@mixin link_opacity {
  @include anime03;
  a:link:hover, a[href]:hover {
    opacity: 0.6;
    filter: alpha(opacity=0.6);
    -ms-filter: "alpha(opacity=0.6)";
    -moz-opacity: 0.6;
    -khtml-opacity: 0.6;
  }
}

@mixin gradient_color {
  background: rgb(0,0,70);
  background: linear-gradient(115deg, rgba(0,0,70,1) 0%, rgba(60,0,150,1) 50%, rgba(170,0,80,1) 100%); 
}



@mixin parts-section_title {
  position: relative;
  margin-bottom: 46px;
  padding-bottom: 21px;
  border-bottom: solid 2px $color_light_blue;
  font-size: 32px;
  font-weight: bold;
  line-height: 1.4;
  color: $color_main;
  @include breakSP {
    padding-bottom: 16px;
    font-size: 22px;
  }
  &::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 90px;
    height: 10px;
    background-color: $color_main;
    @include breakSP {
      bottom: -5px;
      width: 45px;
      height: 5px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 90px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 0;
    border-color: $color_main transparent transparent transparent;
    @include breakSP {
      bottom: -5px;
      left: 45px;
      border-width: 5px 5px 0 0;
    }
  }
}

@mixin parts-section_detail_title {
  margin-bottom: 15px;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.08em;
  color: $color_text;
  @include breakSP {
    margin-bottom: 10px;
    font-size: 20px;
  }
}
